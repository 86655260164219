import React from "react";

export const Features = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Nuestros clientes y socios comerciales</h2>
          <div className="logoContainer">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className="col-md-3 col-sm-6">
                  {" "}
                  <img src={d.img} className= "customers" alt=""></img>
                </div>
              ))
            : "Loading..."}
        </div>
        </div>
      </div>
    </div>
  );
};
